export enum HomePageEvent {
    CarouselButtonClicked = 'HomePage.CarouselButtonClicked',
    CarouselCloseButtonClicked = 'HomePage.CarouselCloseButtonClicked',
    SideBarLinkClicked = 'HomePage.SideBarLinkClicked',
    SideBarStudioLinkClicked = 'HomePage.SideBarStudioLinkClicked',
    SideBarStudioWebButtonClicked = 'HomePage.SideBarStudioWebButtonClicked',
    SubmitIdeaToAutomationHubClicked = 'HomePage.SubmitIdeaToAutomationHubClicked',
    SelectedHomePage = 'HomePage.SelectedHomePage',
    CardActionClicked = 'HomePage.CardActionClicked',
    CardClicked = 'HomePage.CardClicked',
    LargeCardClicked = 'HomePage.LargeCardClicked',
    TableActionClicked = 'HomePage.TableActionClicked',
    TableRowClicked = 'HomePage.TableRowClicked',
    WidgetRenderFetch = 'HomePage.WidgetRenderFetch',
    WidgetRenderSelection = 'HomePage.WidgetRenderSelection',
    WidgetRenderDataEmpty = 'HomePage.WidgetRenderDataEmpty',
    WidgetHeaderSeeAllClicked = 'HomePage.WidgetHeaderSeeAllClicked',
    WidgetHeaderCreateClicked = 'HomePage.WidgetHeaderCreateClicked',
}

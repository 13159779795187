import type { CommandTableEntry } from '@experiences/interfaces';

import { get } from './Requests';

export function checkCommandTable(operationID: string, organizationId: string, accessToken: string, globalOperation = false) {
    const orgIdBaseURL = globalOperation ? `/${organizationId}/portal_` : '';
    return get<CommandTableEntry>(orgIdBaseURL + `/api/organization/checkCommandTable`, {
        urlParams: {
            operationID,
            organizationId,
        },
        accessToken,
        ignoreBasePath: globalOperation,
    });
}

export async function commandTableOperationResult(operationId: string, organizationId: string, token: string, globalOperation?: boolean) {
    const data = await checkCommandTable(operationId, organizationId, token, globalOperation);
    if (data.operationStatus.endsWith('ed') && data.operationStatus !== 'Queued') {
        return data.operationStatus;
    }
    return undefined;
}

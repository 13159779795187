import {
    useEffect,
    useState,
} from 'react';

import {
    getFeatureFlagValue,
    getIsLocalStorageFeatureFlagsEnabled,
} from './FeatureFlagUtil';

export const useFeatureFlagValue = (featureFlagKey: string) => {
    const [ value, setValue ] = useState<boolean>(getFeatureFlagValue(featureFlagKey));

    useEffect(() => {
        const updateFeatureFlagValue = (event: StorageEvent) => {
            if (event.key !== featureFlagKey) {
                return;
            }

            if (event.newValue == null) {
                setValue(getFeatureFlagValue(featureFlagKey));
            } else {
                setValue(event.newValue?.toLowerCase() === 'true');
            }
        };

        const isLocalFeatureFlagsEnabled = getIsLocalStorageFeatureFlagsEnabled(featureFlagKey);

        if (isLocalFeatureFlagsEnabled) {
            window.addEventListener('storage', updateFeatureFlagValue);
        }

        return () => isLocalFeatureFlagsEnabled ? window.removeEventListener('storage', updateFeatureFlagValue) : undefined;
    }, [ featureFlagKey ]);

    return value;
};

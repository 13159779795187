import { ENVIRONMENT } from '@experiences/constants';

import { getEnvironment } from './EnvironmentUtil';

export function getDeploymentVersion() {
    const isDevelopment = getEnvironment() === ENVIRONMENT.LOCAL;
    if (isDevelopment) {
        return 'development';
    }

    const version = document.head.querySelector<HTMLMetaElement>('meta[name="ui-version"]')?.content;
    return version ?? 'unknown';
}

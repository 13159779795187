import {
    ENVIRONMENT,
    LocalGlobalAppOrigin,
    LocalPortalOrigin,
    SubscriptionType,
} from '@experiences/constants';
import type { RouteObject } from 'react-router-dom';
import { matchPath } from 'react-router-dom';

import UiStorage from '../global/UiStorage';
import { getEnvironment } from './EnvironmentUtil';
import type { FirstPartyServiceRedirect } from './FirstPartyServiceUtil';
import {
    firstPartyServiceRedirectOptions,
    MARKETPLACE_STORAGE_KEY,
} from './FirstPartyServiceUtil';

export function getOrganizationAppOrigin() {
    if (getEnvironment() === ENVIRONMENT.LOCAL) {
        return LocalPortalOrigin;
    }

    return window.location.origin;
}

export function getGlobalAppOrigin() {
    if (getEnvironment() === ENVIRONMENT.LOCAL) {
        return LocalGlobalAppOrigin;
    }

    return window.location.origin;
}

export function shouldLoadApplicationWithAPath(pathName: string, routes: RouteObject[]) {
    if (pathName === '/') {
        return true;
    }

    // filter out '/' because it matches all paths
    return routes
        .map(route => route.path)
        .filter(path => !!path && path !== '/')
        .some(path => !!matchPath(path!, pathName));
}

export function loadOrganizationApp(
    organizationName: string,
    organizationId: string,
    extraParams?: any,
    serviceRedirect?: { service: FirstPartyServiceRedirect; route: string }
) {
    const ecommerceRedirect = UiStorage.getItem('ecommerceRedirect') ?? 'false';
    const redirectPath = UiStorage.getItem('redirectPath') ?? '';
    const marketplaceRedirectUri = UiStorage.getItem(MARKETPLACE_STORAGE_KEY) ?? '';

    if (ecommerceRedirect) {
        UiStorage.removeItem('ecommerceRedirect');
    }
    if (redirectPath) {
        UiStorage.removeItem('redirectPath');
    }
    if (marketplaceRedirectUri) {
        UiStorage.removeItem(MARKETPLACE_STORAGE_KEY);
    }

    if (serviceRedirect) {
        const { tenantLevelService } = firstPartyServiceRedirectOptions[serviceRedirect.service];

        // we don't know the tenant name, so for logins tenant level services must be excluded
        if (!tenantLevelService) {
            navigateToFirstPartyService(organizationName, new URLSearchParams(), serviceRedirect.service, serviceRedirect.route);
            return;
        }
    }

    const params: any = {
        organizationId,
        ecommerceRedirect,
        redirectPath,
        ...extraParams,
    };
    const search = new URLSearchParams(params);

    window.location.assign(`${getOrganizationAppOrigin()}/${organizationName}/portal_/cloudrpa?${search.toString()}`);
}

export function loadOrganizationAppByAccount(organizationName: string, origin = getOrganizationAppOrigin()) {
    window.location.assign(`${origin}/${encodeURIComponent(organizationName)}/portal_/cloudrpa`);
}

/**
 * this will load the provided retryUrl
 * @returns boolean - true if the url is valid and the page is redirected
 */
export function loadRetryUrl(retryUrl: string) {
    const url = `${getOrganizationAppOrigin()}${retryUrl}`;

    if (!isValidReturnUrl(url)) {
        return false;
    }

    window.location.assign(`${getOrganizationAppOrigin()}${retryUrl}`);

    return true;
}

export function loadGlobalApp() {
    window.location.assign(getGlobalAppOrigin());
}

export function isFreeSkuEnabled() {
    return UiStorage.getItem('subscriptionPlan') === SubscriptionType.FREE_SKU;
}

export function navigateToFirstPartyService(
    logicalName: string, search: URLSearchParams, service: FirstPartyServiceRedirect, redirectPath: string | null = '',
) {
    const {
        tenantLevelService, includeSearch,
    } = firstPartyServiceRedirectOptions[service];

    const tenantPath = tenantLevelService ? '/DefaultTenant' : '';
    const searchParams = includeSearch ? `?${search.toString()}` : '';

    window.location.assign(`${window.location.origin}/${logicalName}${tenantPath}/${service}_${redirectPath}${searchParams}`);
}

export function navigateToFirstPartyServiceInvite(
    logicalName: string, service: FirstPartyServiceRedirect, tenantId?: string
) {
    const tenantPath = tenantId ? `/${tenantId}` : '';

    window.location.assign(`${window.location.origin}/${logicalName}${tenantPath}/${service}_`);
}

export function isValidReturnUrl(returnUrl: string) {
    const validReturnUrlPath = '/identity_/connect/authorize/callback';

    try {
        const url = new URL(returnUrl);

        if (!url.origin.match(/https:\/\/[a-zA-Z0-9.]+\.uipath\.(com|us)/)) {
            return false;
        }

        if (!url.pathname.startsWith(validReturnUrlPath)) {
            return false;
        }

        return true;
    } catch (e) {
        return false;
    }
}
